exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-gorilla-squad-jsx": () => import("./../../../src/pages/gorilla-squad.jsx" /* webpackChunkName: "component---src-pages-gorilla-squad-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-info-jsx": () => import("./../../../src/pages/info.jsx" /* webpackChunkName: "component---src-pages-info-jsx" */),
  "component---src-pages-privacy-cookies-policy-jsx": () => import("./../../../src/pages/privacy-cookies-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-cookies-policy-jsx" */),
  "component---src-pages-return-policy-jsx": () => import("./../../../src/pages/return-policy.jsx" /* webpackChunkName: "component---src-pages-return-policy-jsx" */),
  "component---src-pages-team-jsx": () => import("./../../../src/pages/team.jsx" /* webpackChunkName: "component---src-pages-team-jsx" */),
  "component---src-pages-terms-and-conditions-jsx": () => import("./../../../src/pages/terms-and-conditions.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-jsx" */),
  "component---src-pages-videos-jsx": () => import("./../../../src/pages/videos.jsx" /* webpackChunkName: "component---src-pages-videos-jsx" */),
  "component---src-templates-member-jsx": () => import("./../../../src/templates/member.jsx" /* webpackChunkName: "component---src-templates-member-jsx" */),
  "component---src-templates-product-jsx": () => import("./../../../src/templates/product.jsx" /* webpackChunkName: "component---src-templates-product-jsx" */),
  "component---src-templates-shop-jsx": () => import("./../../../src/templates/shop.jsx" /* webpackChunkName: "component---src-templates-shop-jsx" */)
}

